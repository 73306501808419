import React, { useState } from "react"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"
import useLockBodyScroll from "../custom/use-lock-body-scroll"
import Img from "gatsby-image"
import { Button } from "./buttons"

import Consulta from "./headerConsulta"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import logo from "../images/svg/IPMD_logoVertical.svg"
import livroReclamacoes from '../images/svg/livro-reclamacao.svg'
import face from "../images/svg/facebook_logo.svg"
import insta from "../images/svg/Instagram_logo.svg"

const Footer = ({ data }) => {

  const [modal, setModal] = useState(false);
  useLockBodyScroll(modal);

  return (
    <StyledFooter>
      <CSSTransition in={modal === true} timeout={350} classNames={"switch"} unmountOnExit>
        <Consulta setModal={setModal} />
      </CSSTransition>
      <div className="top">

        <div className="info">
          <Link className="logo" to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
          <div className="infoContent">
            <h3 className={font.nexaXBold}>{data.title}</h3>
            <Button regular noHover onClick={() => { setModal(!modal) }} className={font.nexaBold + " consulta"}><p>{data.calendarTxt}</p></Button>
            <div className="infoTxt">
              {data.schedule.map((data, i) => (<p key={i} className={font.nexaRegularItalic}>{data.days} <span className={font.nexaXBold}>{data.hours}</span></p>))}
            </div>
            <div className="infoTxt">
              {data.phone.map((data, i) => (<div style={{ marginBottom: "16px" }}><a href={"tel:" + data.ind + " " + data.number} key={i} className={font.nexaRegularItalic}>{data.ind}<span className={font.nexaXBold}> {data.number}</span><br></br><small>{data.txt}</small></a></div>))}
            </div>
            <div className="infoTxt">
              {data.address.map((data, i) => (<p key={i} className={font.nexaXBold}>{data}</p>))}
            </div>
          </div>
        </div>

        <a href="https://www.google.com/maps/place/IPMD/@40.6444453,-8.630737,15z/data=!4m2!3m1!1s0x0:0x7997a5a6efca764e?sa=X&ved=2ahUKEwiq66OkzNLsAhWBZMAKHaVADwMQ_BIwGnoECCQQBQ" target="_blank" rel="noreferrer nofollow" className="map">
          <Img fluid={data.map.childImageSharp.fluid} style={{ height: `100%` }} imgStyle={{ objectFit: "contain" }} />
        </a>
      </div>

      <div className="bottom">
        <p className={font.nexaRegular}>{data.bottomTxt[0]} {new Date().getFullYear()} {data.bottomTxt[1]} {data.bottomTxt[2]} <a href="https://invisual.pt/" target="_blank" rel="noreferrer nofollow">{data.bottomTxt[3]}</a></p>
        <a href="/termos-e-condicoes" rel="noreferrer nofollow" className={font.nexaRegular}>{data.bottomTxt[4]}</a>
        <a href="/politica-de-privacidade" rel="noreferrer nofollow" className={font.nexaRegular}>{data.bottomTxt[5]}</a>
        <div className="social">
          <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank" rel="noreferrer nofollow" className="icon reclamacao-icon">
            <img src={livroReclamacoes} alt="Livro de Reclamações" />
          </a>
          <a href="https://www.facebook.com/pages/category/General-Dentist/IPMD-Instituto-Portugu%C3%AAs-de-Medicina-Dent%C3%A1ria-121969059199006/" target="_blank" rel="noreferrer nofollow" className="icon">
            <img src={face} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/ipmdmedicinadentaria/" target="_blank" rel="noreferrer nofollow" className="icon">
            <img src={insta} alt="Instagram" />
          </a>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.div`
color: #fff;
  .top{
    background-color: #02374e;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .info{
      display: flex;
      margin: 3.5vw 0;
      .logo{
        width: 15vw;
        align-self: baseline;
        position: relative;
        top: -20px;
        img{display: block; width: 40%; margin: auto;}
      }
      .consulta{
        text-transform: uppercase;
        margin-top: 2rem;
        width: fit-content;
        padding: 30px;
        color: #17d4e3;
        border: 2px solid #17d4e3;
        border-radius: 9px;
        transition: all 0.3s;
        :hover{
          color: #fff;
          border: 2px solid #fff; 
        }
      }
      .infoTxt{
        margin: 2rem 0;
      }
    }
    .map{
      width: 800px;
      margin-right: 60px;
    }
  }
  .bottom{
    background-color: #02374e;
    box-shadow: 0 1.5px 18px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    // justify-content: space-between;
    padding: 4vh 8vw 4vh 8vw;
    gap: 0vw;
    justify-content: space-between;
    width: 100%;
    a{
      display: inline-block;
    }
    .social{
      display: flex;
      // justify-content: space-between;
     justify-content:center;
      //margin-right: 10vw;
      .icon{
        // width: 25px;
        height: 1.302vw;
        margin-left: 1.6rem;
        // img{width: 100%; height: 100%}
        img{width:auto; height: 2vh}
      }
    }
  }
`