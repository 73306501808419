import React from "react"
import { Link } from "gatsby"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'

import arrow from "../images/svg/areaArrowGrey.svg"

const Intro = ({data, blackBlue, blueBlack, presentation, mobile}) => (
  <StyledIntro fluid={data.image.childImageSharp.fluid} presentation={presentation} areaPres={data.area}>
    <div className="content" style={{top: mobile ? "60vh" : ""}}>
      <h1 className={font.financierMediumItalic + ' title'} style={{color: blackBlue ? "#717171" : blueBlack && "#17D4E3"}}>{data.title}</h1>
      {!data.area && <h4 className={font.nexaBold + ' subtitle'} style={{color: blueBlack && "#717171"}}>{data.subtitle}</h4>}
      {presentation && <p className={font.nexaRegular + " text"}>{presentation[0]} <span className={font.nexaRegularItalic + " textLink"}><Link to="/clinica#corpo-clinico">{presentation[1]}</Link></span> {presentation[2]}</p>}
      {data.area && <p className={font.nexaRegular + " text textArea"} style={{whiteSpace: "nowrap"}}><Link to="/areas-clinicas#areasAnchor" style={{textDecoration: "underline"}}>ÁREAS CLÍNICAS</Link> <img src={arrow} alt="seta"/> {data.area}</p>}
    </div>
  </StyledIntro>
)

export default Intro

const StyledIntro = styled(BackgroundImage)`

  h1 {
    font-size: calc(35px + 20 * (100vw - 768px) / 1152) !important;
  }

  /* background-attachment: fixed; */
  background-size: cover;
  position: relative;
  width: 100%;
  height: 120vh; /*58vw;*/
  z-index: 1;
  .content{
    position: absolute;
    top: ${props => props.presentation ? "60vh" : props.areaPres ? "60vh" : "33vh"};
    left: 15vw;
    right: 15vw;
    .title{
      color: #fff;
    }
    .subtitle{
      color: #17D4E3;
      text-transform: uppercase;
      margin-top: 0.4em;
    }
    .text{
      color: #717171;
      margin-top: 1.5em;
      width: 50%;
      .textLink{
        text-decoration: underline;
        display: inline-block;
      }
      &.textArea{
        display: flex;
        align-items: center;
        text-transform: uppercase;
      }
      img{
        height: 1em;
        margin: 0 2em;
      }
    }
  }

  @media (max-width: 990px){
    height: 70vh;
  }
`